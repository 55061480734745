// Variables
$primary-color: #61DAFB;
$offwhite-color: #EEEEEE;



// Mixins
@mixin buttonStyle {
    background: none;
    border: 1px solid $offwhite-color;
    border-radius: 5px;
    color: $offwhite-color;
    cursor: pointer;
    font-size: 1rem;
    padding: 0.5rem 1rem;
    transition: all 300ms;

    &:hover, 
    &:focus {
        border-color: $primary-color;
        color: $primary-color;
    }
}

@mixin dottedLineSeperator {
    border-top: 9px dotted rgba($primary-color, 0.3);
    display: inline-block;
    padding-top: 1rem;
}



body {
    background-color: #282828;
    color: $offwhite-color;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    font-size: 16px;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    margin: 0;
}

h2 {
    @include dottedLineSeperator;
}
h3 {
    color: $primary-color;
}

ul {
    margin-top: 0;
}
ol {
    li {
        margin-bottom: 0.5rem;
    }
}

.skip-link {
    color: $primary-color;
    box-sizing: border-box;
    padding: 10px;
    text-decoration: none;
  
    &:focus {
        top: 0;
    }
    &:not(:focus) {
        font-size: 0;
        padding: 0;
    }
}

code {
    background-color: #222222;
    color: gold;
    display: block;
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    font-size: 0.9rem;
    padding: 4px 8px;
}

.visuallyHidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }



header {
    align-items: center;
    background-color: #333333;
    border-bottom: 1px solid #111111;
    border-top: 3px solid $primary-color;
    color: $offwhite-color;
    display: flex;
    justify-content: space-between;
    padding: 8px 20px;

    .header-left { display: flex; }

    img {
        height: 40px;
    }

    nav {
        display: flex;

        .burger {
            display: none;

            @media (max-width: 600px) {
                background: none;
                border: none;
                cursor: pointer;
                display: block;
                height: 40px;
                position: relative;
                width: 40px;
    
                &:hover,
                &:focus {
                    [class*='bar'] {
                        background-color: $primary-color;
                    }
                }

                &.open {
                    .bar1 {
                        opacity: 0;
                        top: 50%;
                    }
                    .bar2 {
                        transform: rotate(-45deg);
                    }
                    .bar3 {
                        transform: rotate(45deg);
                    }
                    .bar4 {
                        bottom: 50%;
                        opacity: 0;
                    }
                }
                .bar1, .bar2, .bar3, .bar4 {
                    background-color: $offwhite-color;
                    border-radius: 3px;
                    height: 3px;
                    left: 20%;
                    position: absolute;
                    top: calc(50% - 1.5px);
                    transform-origin: center;
                    transition: all 300ms;
                    width: 60%;
                }
                .bar1 { transform: translateY(-9px); }
                .bar4 { transform: translateY(9px); }
            }
        }

        .menu {
            list-style: none;
            margin: 0;
            padding: 0;

            @media (min-width: 601px) {
                display: flex;
                justify-content: space-between;
            }
            @media (max-width: 600px) {
                box-sizing: border-box;
                flex-direction: column;
                height: calc(100vh - var(--header-height));
                left: 0;
                opacity: 0;
                padding-top: 12px;
                pointer-events: none;
                position: absolute;
                top: var(--header-height);
                width: 100%;
                z-index: 1;

                .menu-open & {
                    opacity: 1;
                    pointer-events: all;
                }
            }

            li {
                display: block;
                text-align: right;

                @media (max-width: 600px) {
                    margin-right: 12px;
                }
                @media (min-width: 601px) {
                    flex-grow: 1;
                    text-align: center;
                }

                a {
                    color: $offwhite-color;
                    display: inline-block;
                    padding: 1rem;
                    text-decoration: none;
                    transition: color 300ms;

                    @media (max-width: 600px) {
                        background-color: #282828;
                        font-size: 1.25rem;
                    }

                    &:hover,
                    &:focus {
                        color: $primary-color;
                    }
                }
            }
        }
    }
}



.container {
    background-color: #282828;
    display: flex;
    flex-direction: column;

    .main-content {
        flex: 1; /* This makes the main content take up all remaining space in the container, pushing the footer to the bottom */
        margin: 0 auto;
        max-width: 1200px; /* This sets a maximum width for the content and centers it horizontally */
        position: relative;
        width: 90%; /* This sets the content to take up 90% of the available width, leaving 5% margin on each side */

        @media (max-width: 600px) {
            opacity: 1;
            transition: opacity 300ms;
            .menu-open & {
                opacity: 0.04;
            }
        }

        & > div:not(.canvas-container) {
            padding-bottom: 2rem;
        }

        .canvas-container {
            align-items: center;
            display: flex;
            justify-content: center;
            left: 50%;
            position: absolute;
            top: 0;
            transform: translateX(-50%);
            
            h1 {
                color: $primary-color;
                font-size: 50px;
                font-weight: bold;
                left: 50%;
                position: absolute;
                text-align: center;
                transform: translateX(-50%);
            }

            canvas {
                height: 100%;
                width: 100%;
            }
        }
    }
}



// Home
.home-page {
    display: flex;
    flex-direction: column;
    padding: 2rem;

    .home-title {
        font-size: 3rem;
        font-weight: bold;
        margin: 0;
    }

    .home-subtitle {
        font-size: 1.5rem;
        font-weight: 300;
        margin-bottom: 2rem;
        margin-top: 0;
    }

    .home-content {
        align-items: center;
        border: 1px solid $offwhite-color;
        border-radius: 5px;
        box-shadow: 0 3px 0 #111111, 0 3px 0 #111111 inset;
        display: flex;
        width: 100%;

        .home-text {
            padding: 0 1.5rem;

            p {
                font-size: 1.2rem;
                line-height: 1.5;
            }
        }
    }

    .home-image {
        & > * {
            animation: spin-clockwise 10s linear infinite;
            height: 150px;
            transition: all 3s ease-in-out;
            width: 150px;
        }
        &:hover > * {
            height: 200px;
            margin-left: calc(50% - 100px);
            width: 200px;
        }
    }

    @keyframes spin-clockwise {
        from {transform: rotate(0deg);}
        to {transform: rotate(360deg);}
    }
}


// Experience
.job-position {
    padding-bottom: 20px;
    margin-bottom: 20px;

    .job-title {
        color: $primary-color;
        font-size: 1.5em;
        font-weight: bold;
        margin-bottom: 10px;
    }
    .company {
        font-size: 1.3em;
        margin-bottom: 10px;
    }
    .date {
        font-size: 1.1em;
        color: #CCCCCC;
        margin: 0;
    }
    .description {
        font-size: 1.1em;
        line-height: 1.5;

        & > p {
            margin: 0.5rem 0;
        }
    }
    &:not(:last-child) {
        border-bottom: 1px solid gray;
    }
    .description-extended {
        display: block;
        max-height: 0; /* Only show two lines of text */
        overflow: hidden;
        transition: max-height 1s ease-out;

        &.show {
            max-height: 77em;
            transition-duration: 4s;
        }
        & > p:first-child {
            margin-top: 0.5rem;
        }
    }
    .see-more-btn,
    .see-less-btn {
        @include buttonStyle;
        background-color: #333333;
        display: none; /* Initially hidden */

        &.show {
            display: block;
        }
    }
}



// Contact
form {
    align-items: flex-start;
    border: 1px solid $offwhite-color;
    border-radius: 5px;
    display: flex;
    flex-wrap: wrap;
    padding: 1rem;
    transition: height 300ms;

    label {
        flex: 1 1 100%;
        margin-bottom: 1rem;

        input, textarea {
            color: $offwhite-color;
            background: none;
            border-radius: 5px;
            border: 1px solid $offwhite-color;
            font-size: 1rem;
            margin-top: 0.5rem;
            padding: 0.5rem;
            width: calc(100% - 1rem - 2px);
        }
        input {
            display: block;
            width: calc(70% - 1rem - 2px);
        }
        textarea {
            height: 4rem;
            resize: none;
        }
    }
    button[type="submit"] {
        @include buttonStyle;
    }
    .success {
        color: green;
    }
    .error {
        color: red;
    }
}



footer {
    background-color: #333333;
    margin-top: auto;
    padding: 20px;
    text-align: center;

    nav {
        ul {
            list-style: none;
            margin: 0;
            padding: 0;
    
            li {
                display: inline-block;
                padding: 0;
    
                a {
                    color: $offwhite-color;
                    display: inline-block;
                    padding: 12px 8px;
                    text-decoration: none;
        
                    &:hover {
                        color: $primary-color;
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    p {
        margin: 0;

        @media (max-width: 600px) {
            opacity: 1;
            transition: opacity 300ms;
            .menu-open & {
                opacity: 0.04;
            }
        }

        &.copyright {
            @include dottedLineSeperator;
            margin-top: 1rem;
        }
    }
}



/* Fade out the buttons */
.see-more-btn.hide {
    animation: fadeOut 0.5s ease-out;
}
.see-less-btn.hide {
    animation: fadeOut 0.5s ease-out;
}

/* Fade in the buttons */
.see-more-btn.show {
    animation: fadeIn 0.5s ease-in;
}
.see-less-btn.show {
    animation: fadeIn 0.5s ease-in;
}

@keyframes fadeOut {
    from { opacity: 1; }
    to { opacity: 0; }
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}
